import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import Router from 'next/router';
import { connect } from 'react-redux';
import { publish } from '@/utils/publish';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import pluralize from 'pluralize';
import moment from 'moment';
import { RRule } from 'rrule';
import {
  TYPES,
  EVENT_APPOINTMENT,
  EVENT_FOLLOW_UP,
  EVENT_TASK,
  EVENT_OTHER,
  EVENT_UNAVAILABLE,
  EVENT_COPY
} from '@/store/models/event';

import styles from './EventModal.module.scss';

import Modal from '@/components/Base/Modal';
import Submit from '@/components/Forms/Submit';
import FormValidator from '@/components/Forms/Validator';
import EventDetailModal from './EventDetailModal';

import { searchContacts, searchUsers } from '@/store/actions/search.actions';
import { fetchEvents, createEvent, updateEvent, deleteEvent, fetchContactEvents } from '@/store/actions/event.actions';
import { RRULE_WEEK_LABELS } from '@/store/models/event';
import { weekOfMonth, random, setStateAsync, debounce } from '@/utils/helpers';
import { RRULE_WEEK_DAYS } from '@/store/models/event';
import { DEFAULT_COLORS, MATERIAL_COLORS, METRO_COLORS, TAILWINDS_COLORS, APPLE_COLORS } from '@/store/models/event';
import { fetchSmsCampaignTriggers } from '@/store/actions/contacts.actions';
import { config } from '@/config';

const typeId = 'typeId';
const recurrenceId = 'recurrenceId';
const guestsId = 'guestsId';
const guestsSharedId = 'guestsSharedId';
const smsTypeId = 'smsTypeId';

var COLORS = {
  red: '#FF4113',
  light_green: '#00f4bd',
  pink: '#FF006F',
  yellow: '#ffb900',
  orange: '#F39500',
  green: '#02BE4D',
  purple: '#D460E0',
  blue: '#0075C2',
  cyan: '#00C5E4',
  dark_blue: '#334364',
  gray: '#B2BAC6'
};

const initRrule = {
  interval: 1,
  byweekday: [],
  until: null
};

export const RECURRENCE_FREQ = [
  { value: null, label: "Doesn't repeat" },
  { value: RRule.DAILY, label: 'Daily' },
  { value: RRule.WEEKLY, label: 'Weekly' },
  { value: RRule.MONTHLY, label: 'Monthly' },
  {
    value: RRule.YEARLY,
    label: 'Annually'
  }
];

const SEARCH_DELAY_TIME = 500;

class EventModal extends Component {
  initEventDetail = {
    first_name: '',
    last_name: '',
    address: '',
    address_2: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    phone: '',
    cell_phone: '',
    email: '',
    delete: '1'
  };
  state = {
    eventForm: {
      id: null,
      selectedType: '',
      selectedRecurrence: RECURRENCE_FREQ && RECURRENCE_FREQ[0],
      rrule: initRrule,
      recurring_event_id: null,
      recurrence_option: 'current',
      title: this.props.contactData
        ? [this.props.contactData.first_name, this.props.contactData.last_name, this.props.contactData.phone].join(' ')
        : '',
      start_date: null,
      end_date: null,
      ends_on: null,
      start_time: null,
      end_time: null,
      all_day: false,
      completed: false,
      send_invite: false,
      campaign_trigger_id: '',
      color: this.setDefaultColor(),
      description: '', // this.setDescription(),
      event_detail: { ...this.initEventDetail },
      event_guests: [],
      user: {},
      owner: this.props.owner || {},
      nthWeek: false,
      location: this.setLocation(),
      phone: this.setPhone(),
      is_copy: false
    },
    eventDetailModalVisible: false,
    recurringModalVisible: false
  };

  setStateAsync = setStateAsync.bind(this);

  async componentDidMount() {
    const { startDate, type } = this.props;
    const event = this.props.event && this.props.event.eventObject;
    if (startDate) {
      await this.setRoundedTime(moment(startDate).toDate());
    }
    if (!event && !startDate) {
      await this.setRoundedTime();
    }

    if (type) {
      await this.setStateAsync({
        eventForm: { ...this.state.eventForm, selectedType: TYPES.find(({ value }) => value === type) }
      });
    } else {
      await this.setStateAsync({
        eventForm: { ...this.state.eventForm, selectedType: TYPES && TYPES[0] }
      });
    }

    if (this.props.contactData) {
      const { id, first_name, last_name, rm_contact_detail } = this.props.contactData;
      const { tpm_name, funeral_home_name } = rm_contact_detail || {};

      const guest = {
        id,
        first_name: first_name || tpm_name || funeral_home_name,
        last_name,
        type: 'Contact'
      };
      await this.setStateAsync({
        eventForm: {
          ...this.state.eventForm,
          event_guests: this.guestsOptions([guest])
        }
      });
    }

    if (event) {
      let guests = event.event_guests.map(({ guest }) => guest);
      let guest = {};
      if (event.event_detail) {
        guest = {
          id: 'eventDetail',
          first_name: event.event_detail.first_name,
          last_name: event.event_detail.last_name,
          type: 'eventDetail'
        };
        guests = [...guests, guest];
      }
      this.setState({
        eventForm: {
          ...this.state.eventForm,
          id: event.id,
          selectedType: TYPES.find(({ value }) => value === event.type),
          selectedRecurrence: RECURRENCE_FREQ.find(({ value }) => value === (event.rrule && event.rrule.freq)),
          rrule: event.rrule || initRrule,
          title: event.title,
          start_date: event.fc_start && moment(event.fc_start).toDate(),
          end_date:
            event.fc_end &&
            (event.all_day === true ? moment(event.fc_end).add(-1, 'day').toDate() : moment(event.fc_end).toDate()),
          ends_on: event.rrule?.until && moment(event.rrule?.until).toDate(),
          start_time: event.start_time && moment(event.start_time, 'HH:mm').toDate(),
          end_time: event.end_time && moment(event.end_time, 'HH:mm').toDate(),
          all_day: event.all_day,
          completed: event.completed,
          send_invite: event.send_invite,
          color: event.color,
          description: event.description,
          location: event.location,
          phone: event.phone,
          event_detail: event.event_detail || {},
          event_guests: this.mapRedirectedGuests(this.guestsOptions(guests)),
          user: event.user,
          owner: event.owner || {},
          nthWeek: event.nthWeek,
          campaign_trigger_id: event.campaign_trigger_id
        }
      });
    }

    if (config(this.props.currentUser).SMS_FEATURE && !config(this.props.currentUser).SMS_DISABLED)
      await this.fetchSmsCampaignTriggers();
    await this.setColorTheme();
  }

  async fetchSmsCampaignTriggers(q) {
    await this.props.fetchSmsCampaignTriggers({ q, show_on_event: true });
  }

  changeSmsTemplateType = typeObj =>
    this.setState({
      eventForm: { ...this.state.eventForm, campaign_trigger_id: typeObj?.value || null }
    });

  get campaignTriggerOptions() {
    return this.props.campaign_triggers.map(campaign_trigger => ({
      value: campaign_trigger.id,
      label: campaign_trigger.name,
      model: campaign_trigger
    }));
  }

  setDescription() {
    let description = '';
    if (this.props.contactData) {
      const { address, city, state, zip_code } = this.props.contactData;
      if (address) {
        description += `${address}`;
      }
      if (city) {
        description += `\n${city}`;
      }
      if (state) {
        description += ` ${state}`;
      }
      if (zip_code) {
        description += ` ${zip_code}`;
      }
    }
    return description;
  }

  setLocation() {
    let location = '';
    if (this.props.contactData) {
      const { address, city, state, zip_code } = this.props.contactData;

      location = [address, city, state, zip_code].filter(el => el).join(' ');
    }
    return location;
  }

  setPhone() {
    let phone = '';
    if (this.props.contactData) {
      const { phone: contactPhone } = this.props.contactData;
      phone = contactPhone;
    }
    return phone;
  }

  setRoundedTime(startDate) {
    const start_date = startDate || new Date();
    const end_date = startDate || new Date();
    const ends_on = startDate || new Date();
    const start_time = new Date();
    if (![0, 30].includes(start_time.getMinutes())) {
      start_time.setMinutes(start_time.getMinutes() + (30 - (start_time.getMinutes() % 30)));
    }
    const end_time = new Date(start_time.toISOString());
    end_time.setHours(end_time.getHours() + 1);
    this.setState({
      eventForm: {
        ...this.state.eventForm,
        start_date,
        end_date,
        ends_on,
        start_time,
        end_time
      }
    });
  }

  setRecurringDuration() {
    const eventForm = this.state.eventForm;
    eventForm.all_day = false;
    this.setState({ eventForm });
  }

  setColorTheme = async () => {
    COLORS =
      this.props.currentUser.profile.theme == 'default'
        ? DEFAULT_COLORS
        : this.props.currentUser.profile.theme == 'material'
        ? MATERIAL_COLORS
        : this.props.currentUser.profile.theme == 'metro'
        ? METRO_COLORS
        : this.props.currentUser.profile.theme == 'tailwinds'
        ? TAILWINDS_COLORS
        : this.props.currentUser.profile.theme == 'apple'
        ? APPLE_COLORS
        : DEFAULT_COLORS;
  };

  setDefaultColor() {
    let defaultColor = COLORS['light_green'];

    if (this.props.currentUser.profile.theme == 'default') {
      defaultColor = DEFAULT_COLORS['light_green'];
    } else if (this.props.currentUser.profile.theme == 'material') {
      defaultColor = MATERIAL_COLORS['light_green'];
    } else if (this.props.currentUser.profile.theme == 'metro') {
      defaultColor = METRO_COLORS['light_green'];
    } else if (this.props.currentUser.profile.theme == 'tailwinds') {
      defaultColor = TAILWINDS_COLORS['light_green'];
    } else if (this.props.currentUser.profile.theme == 'apple') {
      defaultColor = APPLE_COLORS['light_green'];
    } else {
      defaultColor = DEFAULT_COLORS['light_green'];
    }

    return defaultColor;
  }

  changeType = type => {
    if (type === EVENT_COPY) {
      this.setState({
        eventForm: {
          ...this.state.eventForm,
          is_copy: true
        }
      });
    } else {
      if (type === EVENT_TASK) {
        this.setRecurringDuration();
      }
      this.setState({
        eventForm: {
          ...this.state.eventForm,
          selectedType: TYPES.find(({ value }) => value === type),
          is_copy: false
        }
      });
    }
  };

  changeColor = color => !this.props.disabledFields && this.setState({ eventForm: { ...this.state.eventForm, color } });

  changeRruleFreq = async recurringObj => {
    await this.setStateAsync({
      eventForm: {
        ...this.state.eventForm,
        selectedRecurrence: recurringObj,
        rrule: {
          ...this.state.eventForm.rrule,
          freq: recurringObj.value
        }
      }
    });
    if (recurringObj.value < 3 && recurringObj.value !== null) {
      this.setCurrentWeekDay();
    }
  };

  descriptionVisible = () => {
    return (
      !this.props.currentUser.c_1 || (this.props.currentUser.c_1 && this.type === EVENT_TASK && this.state.eventForm.id)
    );
  };

  get type() {
    return this.state.eventForm.selectedType && this.state.eventForm.selectedType.value;
  }

  get eventForm() {
    COLORS =
      this.props.currentUser.profile.theme == 'default'
        ? DEFAULT_COLORS
        : this.props.currentUser.profile.theme == 'material'
        ? MATERIAL_COLORS
        : this.props.currentUser.profile.theme == 'metro'
        ? METRO_COLORS
        : this.props.currentUser.profile.theme == 'tailwinds'
        ? TAILWINDS_COLORS
        : this.props.currentUser.profile.theme == 'apple'
        ? APPLE_COLORS
        : DEFAULT_COLORS;

    return (
      <form autoComplete="off" name="eventForm" className={styles.eventModalForm}>
        <div className={classNames('field', styles.field, styles.defaultSpaceDown)}>
          <div className={classNames('control', styles.control)}>
            <p className={styles.eventFormTitle}>Title</p>
            <input
              disabled={this.props.disabledFields}
              id="title"
              type="text"
              name="title"
              placeholder="Title"
              onChange={this.validateOnChange}
              value={this.state.eventForm.title}
              className="input is-large"
            />
          </div>
        </div>
        {this.date}
        {this.recurrenceOptions}
        {this.type !== EVENT_UNAVAILABLE && this.descriptionVisible() && (
          <div className={classNames('field', styles.field, styles.defaultSpaceDown)}>
            <p className={styles.eventFormTitle}>Description</p>
            <div className={classNames('control', styles.control)}>
              <textarea
                disabled={this.props.disabledFields}
                name="description"
                onChange={this.validateOnChange}
                value={this.state.eventForm.description || ''}
                placeholder="Description"
                className="textarea is-medium has-fixed-size"
              />
            </div>
          </div>
        )}

        <div className="columns">
          <div className="column is-two-thirds">
            <div className={classNames('field', styles.field, styles.defaultSpaceDown)}>
              <div className={classNames('control', styles.control)}>
                <p className={styles.eventFormTitle}>
                  Location{' '}
                  {this.state.eventForm.location && this.state.eventForm.location != '' && (
                    <a
                      href={'https://maps.google.com/?q=' + this.state.eventForm.location}
                      target="_blank"
                      rel="noreferrer"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <i className={classNames('fas fa-map', styles.interactiveButton)} />
                    </a>
                  )}
                </p>
                <input
                  disabled={this.props.disabledFields}
                  id="location"
                  type="text"
                  name="location"
                  placeholder="Location"
                  onChange={this.validateOnChange}
                  value={this.state.eventForm.location || ''}
                  className="input is-large"
                />
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className={classNames('field', styles.field, styles.defaultSpaceDown)}>
              <div className={classNames('control', styles.control)}>
                <p className={styles.eventFormTitle}>
                  Phone{' '}
                  {this.state.eventForm.phone && this.state.eventForm.phone != '' && (
                    <a
                      href={'tel:' + this.state.eventForm.phone}
                      target="_blank"
                      rel="noreferrer"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <i className={classNames('fas fa-phone', styles.interactiveButton)} />
                    </a>
                  )}
                </p>
                <input
                  disabled={this.props.disabledFields}
                  id="phone"
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  onChange={this.validateOnChange}
                  value={this.state.eventForm.phone || ''}
                  className="input is-large"
                />
              </div>
            </div>
          </div>
        </div>

        {this.type !== EVENT_TASK && this.type !== EVENT_UNAVAILABLE && (
          <div className={styles.colorpickerWrapper}>
            <p className={styles.eventFormTitle}>Color</p>
            <div className={styles.mainColors}>
              {/* // COLORS */}
              {Object.keys(COLORS).map(key => (
                <div
                  key={key}
                  onClick={() => {
                    this.changeColor(COLORS[key]);
                  }}
                  className={classNames(
                    styles.color,
                    styles[key],
                    this.state.eventForm.color === COLORS[key] && styles.active
                  )}
                >
                  <span className="mbri-success" />
                </div>
              ))}
            </div>
          </div>
        )}
        {this.connectContact}

        {this.state.eventForm.id && (
          <p>
            This event is created by{' '}
            <span className={styles.eventFormTitle}>{this.state.eventForm?.user?.profile?.full_name} </span> on{' '}
            {moment(this.props.event.eventObject.created_at).format('MM/DD/YYYY h:mm:ss A')}
          </p>
        )}

        <div className={styles.submitWrapper}>
          <div className={styles.submitBtnWrapper}>
            {this.state.eventForm.id && (
              <button
                type="button"
                disabled={this.props.disabledFields}
                className="button is-large is-danger"
                onClick={this.onDelete}
              >
                <p>Delete</p>
              </button>
            )}
            <button
              disabled={this.props.disabledFields}
              type="button"
              className="button is-large is-secondary"
              onClick={this.props.onClose}
            >
              <p>Cancel</p>
            </button>
            {this.state.eventForm.is_copy && this.state.eventForm.id ? (
              <Submit
                disabled={this.props.disabledFields}
                label={'Create'}
                className="button is-large is-warning"
                onSubmit={this.onCopy}
              />
            ) : (
              <Submit
                disabled={this.props.disabledFields}
                label={this.state.eventForm.id ? 'Update' : 'Create'}
                className="button is-large is-primary"
                onSubmit={this.onSubmit}
              />
            )}
          </div>
        </div>
        <div className={styles.recurrenceModalWrapper}>{this.recurrenceModal}</div>
      </form>
    );
  }

  get modalTabs() {
    const { selectedType, is_copy } = this.state.eventForm;

    return (
      <div className={classNames('tabs', 'is-fullwidth', styles.tabs, styles.isFullwidth)}>
        <ul>
          <li
            onClick={() => !this.props.disabledFields && this.changeType(EVENT_APPOINTMENT)}
            className={classNames(selectedType.value === EVENT_APPOINTMENT && !is_copy && styles.isActive)}
          >
            <a>Appointment</a>
          </li>
          {!this.props.currentUser.c_1 && (
            <li
              onClick={() => !this.props.disabledFields && this.changeType(EVENT_FOLLOW_UP)}
              className={classNames(selectedType.value === EVENT_FOLLOW_UP && !is_copy && styles.isActive)}
            >
              <a>Follow Up</a>
            </li>
          )}

          {(config().CAREINADVANCE || !this.props.contactData) && !this.props.currentUser.c_1 && (
            <li
              onClick={() => !this.props.disabledFields && this.changeType(EVENT_TASK)}
              className={classNames(selectedType.value === EVENT_TASK && !is_copy && styles.isActive)}
            >
              <a>Task</a>
            </li>
          )}
          {!this.props.currentUser.c_1 && (
            <li
              onClick={() => !this.props.disabledFields && this.changeType(EVENT_OTHER)}
              className={classNames(selectedType.value === EVENT_OTHER && !is_copy && styles.isActive)}
            >
              <a>Other</a>
            </li>
          )}
          {!this.props.contactData && !this.props.currentUser.c_1 && (
            <li
              onClick={() => !this.props.disabledFields && this.changeType(EVENT_UNAVAILABLE)}
              className={classNames(selectedType.value === EVENT_UNAVAILABLE && !is_copy && styles.isActive)}
            >
              <a>Unavailable</a>
            </li>
          )}
          <li
            onClick={() => !this.props.disabledFields && this.changeType(EVENT_COPY)}
            className={classNames(is_copy && styles.isActive)}
          >
            <a>Copy</a>
          </li>
        </ul>
      </div>
    );
  }

  setCurrentWeekDay = () => {
    const currentWeekDay = moment(this.props.startDate).day();

    if (!this.state.eventForm.rrule.byweekday || this.state.eventForm.rrule.byweekday.length === 0) {
      switch (currentWeekDay) {
        case 0:
          this.changeRruleByweekday(RRule.SU);
          break;
        case 1:
          this.changeRruleByweekday(RRule.MO);
          break;
        case 2:
          this.changeRruleByweekday(RRule.TU);
          break;
        case 3:
          this.changeRruleByweekday(RRule.WE);
          break;
        case 4:
          this.changeRruleByweekday(RRule.TH);
          break;
        case 5:
          this.changeRruleByweekday(RRule.FR);
          break;
        case 6:
          this.changeRruleByweekday(RRule.SA);
          break;
      }
    }
  };

  changeRruleByweekday = day => {
    const { byweekday } = this.state.eventForm.rrule;
    if (byweekday?.includes(day)) {
      this.setState({
        eventForm: {
          ...this.state.eventForm,
          rrule: {
            ...this.state.eventForm.rrule,
            byweekday: byweekday.filter(el => el !== day)
          }
        }
      });
    } else {
      this.setState({
        eventForm: {
          ...this.state.eventForm,
          rrule: {
            ...this.state.eventForm.rrule,
            byweekday: byweekday ? [...byweekday, day] : [day]
          }
        }
      });
    }
  };

  get repeatEveryLabel() {
    let label = '';
    switch (this.freq) {
      case RRule.DAILY:
        label = 'day';
        break;
      case RRule.WEEKLY:
        label = 'week';
        break;
      case RRule.MONTHLY:
        label = 'month';
        break;
      case RRule.YEARLY:
        label = 'year';
        break;
    }
    return `${label}(s)`;
  }

  inputWidth = value => {
    return value && value.length ? (value.length + 1) * 24 + 'px' : 50 + 'px';
  };

  get recurrenceOptions() {
    if (this.type === EVENT_TASK) return null;

    return (
      <div className={styles.recWrapper}>
        <div className={styles.defaultSpaceDown}>
          <div className={classNames('row', styles.row)}>
            <div className={styles.start}>
              <div className={classNames('control', styles.control)}>
                <h4 className={styles.eventFormTitle}>Recurring</h4>
                <div className={styles.flexCenter}>
                  <div className={classNames('field', styles.eventRecurrence, styles.eventRecurrence, styles.field)}>
                    <Select
                      isDisabled={this.props.disabledFields}
                      menuPlacement={this.state.eventForm.selectedType.value === EVENT_UNAVAILABLE ? 'top' : 'bottom'}
                      instanceId={recurrenceId}
                      classNamePrefix="custom-select"
                      className={classNames('custom-select', styles.recurrenceSelect)}
                      value={this.state.eventForm.selectedRecurrence}
                      onChange={this.changeRruleFreq}
                      options={RECURRENCE_FREQ}
                    />
                  </div>
                  <div className={classNames('field', styles.field, styles.flexCenter)}>
                    <input
                      disabled={this.props.disabledFields}
                      id="all_day"
                      type="checkbox"
                      name="all_day"
                      className="is-checkradio is-medium"
                      onChange={this.setAllDay}
                      checked={this.state.eventForm.all_day}
                    />
                    <label htmlFor="all_day">
                      <p>All Day</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.end}>
              {this.props.currentUser.c_1 && (
                <div className={classNames('control', styles.control)}>
                  <h4 className={styles.eventFormTitle}>Completed</h4>
                  <div className={styles.flexCenter}>
                    <div className={classNames('field', styles.field, styles.flexCenter)}>
                      <input
                        disabled={this.props.disabledFields}
                        id="completed"
                        type="checkbox"
                        name="completed"
                        className="is-checkradio is-medium"
                        onChange={() =>
                          this.setState({
                            eventForm: { ...this.state.eventForm, completed: !this.state.eventForm.completed }
                          })
                        }
                        checked={this.state.eventForm.completed}
                      />
                      <label htmlFor="completed">
                        <p>{this.state.eventForm.completed ? 'Yes' : 'No'}</p>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {this.freqPresent && (
                <div className={classNames('control', styles.control)}>
                  <h4 className={styles.eventFormTitle}>Repeat every</h4>
                  <div className={styles.flexCenter}>
                    <div className={classNames('field', styles.field, styles.interval)}>
                      <input
                        disabled={this.props.disabledFields}
                        style={{ width: this.inputWidth(this.state.eventForm.rrule.interval), maxWidth: '130px' }}
                        id="interval"
                        type="number"
                        name="interval"
                        onChange={() =>
                          this.setState({
                            eventForm: {
                              ...this.state.eventForm,
                              rrule: {
                                ...this.state.eventForm.rrule,
                                interval: event.target.value
                              }
                            }
                          })
                        }
                        value={this.state.eventForm.rrule.interval}
                        className="input is-large"
                        min="1"
                      />
                      <label className={styles.intervalLabel} htmlFor="interval">
                        <p>{this.repeatEveryLabel}</p>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.freqPresent && this.freq != RRule.DAILY && this.freq != RRule.YEARLY && this.freq != RRule.MONTHLY && (
          <div className={styles.defaultSpaceDown}>
            <div className={classNames('row', styles.row)}>
              <div className={classNames('control', styles.control)}>
                <h4 className={styles.eventFormTitle}>Repeat On</h4>
                <div className={classNames('field', styles.field, styles.flexCenter)}>
                  <div className={styles.flexCenter}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_repeat_on_su"
                      type="checkbox"
                      name="rec_repeat_on_su"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeRruleByweekday(RRule.SU)}
                      checked={this.state.eventForm.rrule.byweekday?.includes(RRule.SU) || false}
                    />
                    <label htmlFor="rec_repeat_on_su">
                      <p>Sun</p>
                    </label>
                  </div>
                  <div className={styles.flexCenter}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_repeat_on_mo"
                      type="checkbox"
                      name="rec_repeat_on_mo"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeRruleByweekday(RRule.MO)}
                      checked={this.state.eventForm.rrule.byweekday?.includes(RRule.MO) || false}
                    />
                    <label htmlFor="rec_repeat_on_mo">
                      <p>Mon</p>
                    </label>
                  </div>
                  <div className={styles.flexCenter}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_repeat_on_tu"
                      type="checkbox"
                      name="rec_repeat_on_tu"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeRruleByweekday(RRule.TU)}
                      checked={this.state.eventForm.rrule.byweekday?.includes(RRule.TU) || false}
                    />
                    <label htmlFor="rec_repeat_on_tu">
                      <p>Tue</p>
                    </label>
                  </div>
                  <div className={styles.flexCenter}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_repeat_on_we"
                      type="checkbox"
                      name="rec_repeat_on_we"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeRruleByweekday(RRule.WE)}
                      checked={this.state.eventForm.rrule.byweekday?.includes(RRule.WE) || false}
                    />
                    <label htmlFor="rec_repeat_on_we">
                      <p>Wed</p>
                    </label>
                  </div>
                  <div className={styles.flexCenter}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_repeat_on_th"
                      type="checkbox"
                      name="rec_repeat_on_th"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeRruleByweekday(RRule.TH)}
                      checked={this.state.eventForm.rrule.byweekday?.includes(RRule.TH) || false}
                    />
                    <label htmlFor="rec_repeat_on_th">
                      <p>Thu</p>
                    </label>
                  </div>
                  <div className={styles.flexCenter}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_repeat_on_fr"
                      type="checkbox"
                      name="rec_repeat_on_fr"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeRruleByweekday(RRule.FR)}
                      checked={this.state.eventForm.rrule.byweekday?.includes(RRule.FR) || false}
                    />
                    <label htmlFor="rec_repeat_on_fr">
                      <p>Fri</p>
                    </label>
                  </div>
                  <div className={styles.flexCenter}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_repeat_on_sa"
                      type="checkbox"
                      name="rec_repeat_on_sa"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeRruleByweekday(RRule.SA)}
                      checked={this.state.eventForm.rrule.byweekday?.includes(RRule.SA) || false}
                    />
                    <label htmlFor="rec_repeat_on_sa">
                      <p>Sat</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.freqPresent && this.freq === RRule.MONTHLY && (
          <div className={classNames('field', styles.field, styles.flexCenter)}>
            <input
              disabled={this.props.disabledFields}
              id="nth_week"
              type="checkbox"
              name="nth_week"
              className="is-checkradio is-medium"
              onChange={this.setNthWeek}
              checked={this.state.eventForm.nthWeek}
            />
            <label htmlFor="nth_week">
              <p>
                On the {moment.localeData().ordinal(weekOfMonth(this.state.eventForm.start_date))}{' '}
                {RRULE_WEEK_LABELS[moment(this.state.eventForm.start_date).day()]}
              </p>
            </label>
          </div>
        )}
        {this.freqPresent && (
          <div className={styles.defaultSpaceDown}>
            <div className={classNames('row', styles.row)}>
              <div className={classNames('control', styles.control)}>
                <h4 className={styles.eventFormTitle}>Ends</h4>
                <div className={classNames('field', styles.field, styles.flexCenter)}>
                  <div className={classNames(styles.endsLabel, styles.flexCenter)}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_end_on"
                      type="radio"
                      name="rec_end_on"
                      className="is-checkradio is-medium"
                      onChange={() =>
                        this.setState({
                          eventForm: {
                            ...this.state.eventForm,
                            ends_on: this.state.eventForm.ends_on || new Date(),
                            rrule: {
                              ...this.state.eventForm.rrule,
                              count: ''
                            }
                          }
                        })
                      }
                      checked={this.state.eventForm.ends_on || ''}
                    />
                    <label htmlFor="rec_end_on">
                      <p>On</p>
                    </label>
                  </div>
                  <div className={styles.dateWrapper}>
                    <div className={styles.endDate}>
                      <i className="fas fa-chevron-down" />
                      <DatePicker
                        disabled={this.props.disabledFields}
                        id="end-date"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        minDate={
                          moment(this.state.eventForm.end_date)
                            .set('hour', 0)
                            .diff(moment(this.state.eventForm.start_date).set('hour', 0), 'days') == 1
                            ? moment(this.state.eventForm.start_date).add(1, 'days').toDate()
                            : this.state.eventForm.start_date
                        }
                        selected={this.state.eventForm.ends_on}
                        popover="bottom right"
                        onChange={ends_on => {
                          this.setState({
                            eventForm: {
                              ...this.state.eventForm,
                              ends_on,
                              rrule: {
                                ...this.state.eventForm.rrule,
                                count: ''
                              }
                            }
                          });
                        }}
                        name="ends_on"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input', styles.inputWidth)}
                      />
                    </div>
                  </div>
                </div>
                <div className={classNames('field', styles.field, styles.flexCenter)}>
                  <div className={classNames(styles.endsLabel, styles.flexCenter)}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_end_after"
                      type="radio"
                      name="rec_end_after"
                      className="is-checkradio is-medium"
                      onChange={() => {
                        this.setState({
                          eventForm: {
                            ...this.state.eventForm,
                            ends_on: null,
                            rrule: {
                              ...this.state.eventForm.rrule,
                              count: 1
                            }
                          }
                        });
                      }}
                      checked={this.state.eventForm.rrule.count || ''}
                    />
                    <label htmlFor="rec_end_after">
                      <p>After</p>
                    </label>
                  </div>
                  <div className={styles.flexCenter}>
                    <div className={classNames('field', styles.field, styles.count)}>
                      <input
                        disabled={this.props.disabledFields}
                        style={{ width: this.inputWidth(this.state.eventForm.rrule.count), maxWidth: '130px' }}
                        id="count"
                        type="number"
                        name="count"
                        onChange={() => {
                          this.setState({
                            eventForm: {
                              ...this.state.eventForm,
                              ends_on: null,
                              rrule: {
                                ...this.state.eventForm.rrule,
                                count: event.target.value,
                                until: null
                              }
                            }
                          });
                        }}
                        value={this.state.eventForm.rrule.count || ''}
                        min="1"
                        className="input is-large"
                      />
                      <label className={styles.countLabel} htmlFor="count">
                        <p>occurrence(s)</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={classNames('field', styles.field, styles.flexCenter)}>
                  <div className={classNames(styles.endsLabel, styles.flexCenter)}>
                    <input
                      disabled={this.props.disabledFields}
                      id="rec_end_never"
                      type="radio"
                      name="rec_end_never"
                      className="is-checkradio is-medium"
                      onChange={() =>
                        this.setState({
                          eventForm: {
                            ...this.state.eventForm,
                            ends_on: null,
                            rrule: {
                              ...this.state.eventForm.rrule,
                              count: null,
                              until: null
                            }
                          }
                        })
                      }
                      checked={(!this.state.eventForm.ends_on && !this.state.eventForm.rrule.count) || ''}
                    />
                    <label htmlFor="rec_end_never">
                      <p>Never</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  get duration() {
    if (!this.state.eventForm.start_time || !this.state.eventForm.end_time) return 0;
    const minutesPassed = moment(this.state.eventForm.start_time, 'HH:mm:ss').diff(
      moment(this.state.eventForm.end_time, 'HH:mm:ss'),
      'minutes'
    );
    return (Math.abs(minutesPassed) / 60).toFixed(2);
  }

  get freq() {
    return this.state.eventForm.selectedRecurrence.value;
  }

  get freqPresent() {
    return this.freq != null;
  }

  get date() {
    return (
      <div className={classNames('field', styles.dateWrapper, styles.defaultSpaceDown)}>
        <div className={classNames('row', styles.row)}>
          <div className={styles.start}>
            <div className={classNames('control', styles.control)}>
              <label htmlFor="start_date">
                <p className={styles.eventFormTitle}>{this.type === EVENT_TASK ? 'Due date' : 'Event starts'}</p>
              </label>
              <div className={styles.startDate}>
                <i className="fas fa-chevron-down" />
                <DatePicker
                  disabled={this.props.disabledFields}
                  popperPlacement="top-end"
                  id="start_date"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  closeOnScroll={true}
                  selected={this.state.eventForm.start_date}
                  onChange={start_date => {
                    const nextDay =
                      this.state.eventForm.start_time &&
                      moment(this.state.eventForm.start_time).format('HH:mm') >= '23:00';
                    this.setState({
                      eventForm: {
                        ...this.state.eventForm,
                        campaign_trigger_id: null,
                        start_date,
                        end_date:
                          !nextDay && start_date ? start_date : start_date && moment(start_date).add(1, 'day').toDate(),
                        end_time:
                          this.state.eventForm.start_time &&
                          moment(this.state.eventForm.start_time).add(1, 'hour').toDate()
                      }
                    });
                  }}
                  name="start_date"
                  placeholderText="mm/dd/yyyy"
                  dateFormat="MM/dd/yyyy"
                  className={classNames('input', styles.inputWidth)}
                />
              </div>
            </div>
            {!this.state.eventForm.all_day && (
              <div className={classNames('control', styles.control)}>
                <div className={styles.startTime}>
                  <i className="fas fa-chevron-down" />
                  <DatePicker
                    disabled={this.props.disabledFields}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    closeOnScroll={true}
                    id="start_time"
                    selected={this.state.eventForm.start_time}
                    onChange={time => {
                      const nextDay = time && moment(time).format('HH:mm') >= '23:00';
                      let end_date = this.state.eventForm.end_date && moment(this.state.eventForm.end_date);
                      if (nextDay && end_date) {
                        end_date = end_date.add(1, 'day');
                      }
                      this.setState({
                        eventForm: {
                          ...this.state.eventForm,
                          campaign_trigger_id: null,
                          start_time: time,
                          end_time: time && moment(time).add(1, 'hour').toDate(),
                          end_date:
                            !nextDay && end_date
                              ? this.state.eventForm.start_date
                              : this.state.eventForm.start_date &&
                                moment(this.state.eventForm.start_date).add(1, 'day').toDate()
                        }
                      });
                    }}
                    name="start_time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption=""
                    timeIntervals={30}
                    dateFormat="hh:mm aa"
                    className={classNames('input', styles.inputWidth)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.end}>
            {this.props.currentUser.c_1 && this.type === EVENT_TASK && (
              <div className={classNames('control', styles.control)}>
                <h4 className={styles.eventFormTitle}>Completed</h4>
                <div className={styles.flexCenter}>
                  <div className={classNames('field', styles.field, styles.flexCenter)}>
                    <input
                      disabled={this.props.disabledFields}
                      id="completed"
                      type="checkbox"
                      name="completed"
                      className="is-checkradio is-medium"
                      onChange={() =>
                        this.setState({
                          eventForm: { ...this.state.eventForm, completed: !this.state.eventForm.completed }
                        })
                      }
                      checked={this.state.eventForm.completed}
                    />
                    <label htmlFor="completed">
                      <p>{this.state.eventForm.completed ? 'Yes' : 'No'}</p>
                    </label>
                  </div>
                </div>
              </div>
            )}
            {this.type !== EVENT_TASK && (
              <div className={classNames('control', styles.control)}>
                <label htmlFor="end_date">
                  <p className={styles.eventFormTitle}>
                    Event ends
                    <span
                      className={styles.eventFormTooltip}
                      data-tooltip="This value is exclusive. For example, an event with the end of 2024-09-03 will appear to span through 2024-09-02 but end before the start of 2024-09-03."
                    >
                      <i className="far fa-question-circle" />
                    </span>
                  </p>
                </label>
                <div className={styles.endDate}>
                  <i className="fas fa-chevron-down" />
                  <DatePicker
                    disabled={this.props.disabledFields}
                    id="end-date"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    closeOnScroll={true}
                    minDate={this.state.eventForm.start_date}
                    selected={this.state.eventForm.end_date}
                    popover="bottom right"
                    onChange={end_date => {
                      if (
                        moment(this.state.eventForm.start_date).format('MM/DD/YYYY') ===
                        moment(end_date).format('MM/DD/YYYY')
                      ) {
                        this.setState({
                          eventForm: {
                            ...this.state.eventForm,
                            campaign_trigger_id: null,
                            end_date,
                            end_time:
                              this.state.eventForm.start_time &&
                              moment(this.state.eventForm.start_time).add(1, 'hour').toDate()
                          }
                        });
                      } else {
                        this.setState({
                          eventForm: { ...this.state.eventForm, end_date }
                        });
                      }
                    }}
                    name="end_date"
                    placeholderText="mm/dd/yyyy"
                    dateFormat="MM/dd/yyyy"
                    className={classNames('input', styles.inputWidth)}
                  />
                </div>
              </div>
            )}
            {this.type !== EVENT_TASK && !this.state.eventForm.all_day && (
              <div className={classNames('control', styles.control)}>
                <div className={styles.endTime}>
                  <i className="fas fa-chevron-down" />
                  <DatePicker
                    disabled={this.props.disabledFields}
                    id="end_time"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    closeOnScroll={true}
                    selected={this.state.eventForm.end_time}
                    onChange={time => {
                      this.setState({
                        eventForm: {
                          ...this.state.eventForm,
                          campaign_trigger_id: null,
                          end_time: time
                        }
                      });
                    }}
                    minTime={
                      moment(this.state.eventForm.start_date).format('MM/DD/YYYY') ===
                        moment(this.state.eventForm.end_date).format('MM/DD/YYYY') && this.state.eventForm.start_time
                        ? moment(this.state.eventForm.start_time).add(30, 'minutes').toDate()
                        : new Date().setHours(0, 0, 0, 0)
                    }
                    maxTime={new Date().setHours(23, 30, 0, 0)}
                    name="end_time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption=""
                    timeIntervals={30}
                    dateFormat="hh:mm aa"
                    className={classNames('input', styles.inputWidth)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {!this.state.eventForm.all_day &&
          this.state.eventForm.selectedType.value !== EVENT_TASK &&
          moment(this.state.eventForm.start_date).format('MM/DD/YYYY') ===
            moment(this.state.eventForm.end_date).format('MM/DD/YYYY') && (
            <div className={styles.duration}>
              Duration: {this.duration} {pluralize('hr', this.duration)}
            </div>
          )}
      </div>
    );
  }

  get connectContact() {
    const first_name = this.state.eventForm?.user?.first_name;
    const last_name = this.state.eventForm?.user?.last_name;

    if (![EVENT_APPOINTMENT, EVENT_FOLLOW_UP, EVENT_OTHER].includes(this.type)) return null;
    return (
      <>
        {config(this.props.currentUser).SMS_FEATURE &&
          !config(this.props.currentUser).SMS_DISABLED &&
          this.type === EVENT_APPOINTMENT &&
          (this.props?.currentUser?.sms_subscription?.status == 'active' ||
            this.props?.currentUser?.sms_subscription?.status == 'grace') &&
          this.props.currentUser?.profile?.dialer_verified_phone_number &&
          (this.props?.currentUser?.sms_subscription?.order_type == 'Subscription::ManagerPay' ||
            this.props?.currentUser?.sms_subscription?.order_type == 'Subscription::SelfPay') && (
            <div className={classNames(styles.sharedEvent, styles.defaultSpaceDown)}>
              <p className={styles.eventFormTitle}>SMS Trigger</p>
              <div className={classNames('field', styles.field)}>
                <div className={classNames('control', styles.control)}>
                  <Select
                    instanceId={smsTypeId}
                    classNamePrefix="custom-select"
                    className="custom-select"
                    name="campaign_trigger_id"
                    placeholder="Select a template"
                    isClearable
                    value={this.campaignTriggerOptions.find(
                      el => el.value === this.state.eventForm.campaign_trigger_id
                    )}
                    onChange={this.changeSmsTemplateType}
                    options={this.campaignTriggerOptions}
                  />
                </div>
              </div>
            </div>
          )}
        <div className={classNames(styles.sharedEvent, styles.defaultSpaceDown)}>
          <div className={styles.connectContact}>
            <p className={styles.eventFormTitle}>Connect Contact</p>
            {/* {!config().BROKERAGEASB && (
              <div className={classNames('field', styles.field)}>
                <input
                  disabled={this.props.disabledFields}
                  id="send_invite"
                  type="checkbox"
                  name="send_invite"
                  className="is-checkradio is-medium"
                  onChange={() =>
                    this.setState({
                      eventForm: {
                        ...this.state.eventForm,
                        send_invite: !this.state.eventForm.send_invite
                      }
                    })
                  }
                  checked={this.state.eventForm.send_invite}
                />
                <label htmlFor="send_invite">
                  <p>Send Invite</p>
                </label>
              </div>
            )} */}
          </div>
          <div className={classNames('field', styles.field, styles.searchMember)}>
            <div className={classNames('control', styles.control)}>
              <AsyncSelect
                isDisabled={this.props.disabledFields}
                key={this.state.contactKey}
                instanceId={guestsId}
                classNamePrefix="custom-select"
                className="custom-select"
                isMulti
                cache={false}
                value={this.state.eventForm.event_guests.filter(
                  el => ['Contact', 'eventDetail'].includes(el.guest.type) && !el.delete
                )}
                onChange={this.selectGuests}
                defaultOptions
                loadOptions={this.loadGuestsOptions}
              />
            </div>
          </div>
        </div>
        <div className={classNames(styles.sharedEvent, styles.defaultSpaceDown)}>
          <p className={styles.eventFormTitle}>Shared with</p>
          <div className={classNames('field', styles.field, styles.searchMember, styles.defaultSpaceDown)}>
            <div className={classNames('control', styles.control)}>
              <AsyncSelect
                instanceId={guestsSharedId}
                classNamePrefix="custom-select"
                className="custom-select"
                isMulti
                isDisabled={this.props.disabledFields}
                value={this.state.eventForm.event_guests.filter(el => el.guest.type === 'User' && !el.delete)}
                onChange={this.selectSharedGuests}
                defaultOptions
                loadOptions={this.loadSharedGuestsOptions}
              />
            </div>
          </div>
        </div>
        {first_name && last_name && (
          <p className={styles.createdBy}>
            Created by {first_name} {last_name}
          </p>
        )}
      </>
    );
  }

  setAllDay = async () => {
    await this.setStateAsync({
      eventForm: {
        ...this.state.eventForm,
        all_day: !this.state.eventForm.all_day,
        rrule: { ...this.state.eventForm.rrule, count: null }
      }
    });
    this.setRoundedTime(this.state.eventForm.start_date);
  };

  setNthWeek = async () => {
    const rruleNthWeekDay = RRULE_WEEK_DAYS[moment(this.state.eventForm.start_date).day()].nth(
      weekOfMonth(this.state.eventForm.start_date)
    );

    await this.setStateAsync({
      eventForm: {
        ...this.state.eventForm,
        nthWeek: !this.state.eventForm.nthWeek,
        rrule: {
          ...this.state.eventForm.rrule,
          byweekday: this.state.eventForm.nthWeek ? null : rruleNthWeekDay
        }
      }
    });
  };

  guestsOptions = guests =>
    guests.map((guest, index) => ({
      guest,
      value: guest?.id ?? index,
      label: <p>{[guest?.first_name, guest?.last_name].join(' ')}</p>
    }));

  loadGuestsOptions = debounce(async inputValue => {
    const guests = await this.props.searchContacts({ q: inputValue });
    const { event_detail } = this.state.eventForm;
    let guest;
    if (Object.getOwnPropertyNames(event_detail).length && event_detail.delete !== '1') {
      guest = {
        id: 'eventDetail',
        first_name: event_detail.first_name,
        last_name: event_detail.last_name,
        type: 'eventDetail'
      };
    } else {
      guest = {
        id: 'addEventDetail',
        first_name: '+ Add contact',
        last_name: '',
        type: 'addEventDetail'
      };
    }
    let data = this.guestsOptions([guest, ...guests.data]);

    return new Promise(resolve => resolve(data));
  }, SEARCH_DELAY_TIME);

  loadSharedGuestsOptions = debounce(async inputValue => {
    const sharedGuests = await this.props.searchUsers(inputValue, false, this.props.currentUser.id, {
      shared_with: true
    });
    const data = this.guestsOptions(sharedGuests.data);

    return new Promise(resolve => resolve(data));
  }, SEARCH_DELAY_TIME);

  selectGuests = async value => {
    if (value.find(({ value }) => value === 'addEventDetail')) {
      this.setState({ eventDetailModalVisible: true });
      return;
    }

    const newGuests = value;
    const toDelete = this.state.eventForm.event_guests.filter(
      ({ guest, value }) => !newGuests.find(el => el.value === value) && guest.type === 'Contact'
    );
    const eventDetail = this.state.eventForm.event_guests.find(
      ({ guest, value }) => !newGuests.find(el => el.value === value) && guest.type === 'eventDetail'
    );
    if (eventDetail) {
      await this.cancelEventDetailModal();
    }

    const guests = [
      ...newGuests.map(el => {
        el.delete = undefined;
        return el;
      }),
      ...toDelete.map(el => {
        el.delete = '1';
        return el;
      }),
      ...this.state.eventForm.event_guests.filter(({ guest }) => guest.type === 'User')
    ];

    const firstContactObj = newGuests.filter(({ guest }) => guest.type === 'Contact')[0];
    await this.changeLocationAndPhone(firstContactObj);
    await this.setStateAsync({
      eventForm: {
        ...this.state.eventForm,
        event_guests: this.mapRedirectedGuests(guests)
      },
      contactKey: random()
    });
  };

  changeLocationAndPhone = async guestObj => {
    if (!guestObj) return;

    let { location, phone } = this.state.eventForm;

    if (!location?.length) {
      const { address, city, state, zip_code } = guestObj.guest;

      location = [address, city, state, zip_code].filter(el => el).join(' ');
    }
    if (!phone?.length) {
      const { phone: contactPhone } = guestObj.guest;

      phone = contactPhone;
    }

    await this.setStateAsync({
      eventForm: {
        ...this.state.eventForm,
        location,
        phone
      }
    });
  };

  mapRedirectedGuests = guests => {
    return guests.length > 0
      ? guests.map(el => {
          if (el.guest.type === 'Contact') {
            return {
              ...el,
              label: (
                <p className="guest-option" onClick={e => e.stopPropagation()}>
                  <a target={'_blank'} rel={'noreferrer'} href={`/contacts/${el.guest?.number}`}>
                    {[el.guest.first_name, el.guest.last_name].join(' ')}
                  </a>
                </p>
              )
            };
          } else {
            return el;
          }
        })
      : [];
  };

  redirectToDetails = async id => {
    const { fetchOnActions, onClose } = this.props;
    await Router.push('/contacts/[id]', `/contacts/${id}`);
    fetchOnActions && fetchOnActions(id);
    onClose && onClose();
  };

  selectSharedGuests = value => {
    const newGuests = value;
    const toDelete = this.state.eventForm.event_guests.filter(
      ({ guest, value }) => !newGuests.find(el => el.value === value) && guest.type === 'User'
    );
    const guests = [
      ...newGuests.map(el => {
        el.delete = undefined;
        return el;
      }),
      ...toDelete.map(el => {
        el.delete = '1';
        return el;
      }),
      ...this.state.eventForm.event_guests.filter(({ guest }) => ['Contact', 'eventDetail'].includes(guest.type))
    ];
    this.setState({
      ...this.state,
      eventForm: {
        ...this.state.eventForm,
        event_guests: guests
      }
    });
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  validateOnChangeWithChild = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);
    const parentObject = input.name.split('.')[0];
    const inputName = input.name.split('.')[1];

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [parentObject]: { ...this.state.eventForm[parentObject], [inputName]: value },
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  onDelete = () => {
    this.props
      .deleteEvent(this.state.eventForm.id)
      .then(this.props.onClose)
      .then(() =>
        this.props.fetchEvents({
          downline_user_id: this.props.downlineId,
          start_date: this.props.activeCalendarRange?.start_date,
          end_date: this.props.activeCalendarRange?.end_date
        })
      )
      .then(this.props.onSuccessAction)
      .catch(res => publish('error', res));
  };

  onCopy = (e, callback) => {
    const form = e.target.form;
    const inputs = [...form.elements].filter(
      i =>
        ['INPUT'].includes(i.nodeName) &&
        ![
          `react-select-${typeId}-input`,
          `react-select-${recurrenceId}-input`,
          `react-select-${guestsId}-input`,
          `react-select-${guestsSharedId}-input`,
          `react-select-${smsTypeId}-input`
        ].includes(i.id)
    );
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    e.preventDefault();

    if (hasError) {
      return callback();
    }

    const func = this.props.createEvent;
    func(this.prepareData)
      .then(this.props.onClose)
      .then(this.onSave)
      .then(this.props.onSuccessAction)
      .catch(res => publish('error', res));
    callback();
  };

  onSave = () => {
    this.props.onSave
      ? this.props.onSave()
      : this.props.fetchEvents({
          downline_user_id: this.props.downlineId,
          start_date: this.props.activeCalendarRange?.start_date,
          end_date: this.props.activeCalendarRange?.end_date
        });
  };

  onSubmit = async (e, callback) => {
    const form = e.target.form;
    const inputs = [...form.elements].filter(
      i =>
        ['INPUT'].includes(i.nodeName) &&
        ![
          `react-select-${typeId}-input`,
          `react-select-${recurrenceId}-input`,
          `react-select-${guestsId}-input`,
          `react-select-${guestsSharedId}-input`,
          `react-select-${smsTypeId}-input`
        ].includes(i.id)
    );
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    e.preventDefault();

    if (hasError) {
      return callback();
    }

    // TODO: recurring
    // if (!this.state.recurringModalVisible && this.state.eventForm.id && this.freqPresent) {
    //   // on edit and if recurrence event
    //   this.setState({ recurringModalVisible: true });
    //   return callback();
    // }

    const func = this.state.eventForm.id ? this.props.updateEvent : this.props.createEvent;
    await func(this.prepareData)
      .then(this.props.onClose)
      .then(this.onSave)
      .then(this.props.onSuccessAction)
      .catch(res => publish('error', res));
    callback();
  };

  get prepareData() {
    const {
      id,
      selectedType,
      rrule,
      selectedRecurrence,
      recurrence_option,
      title,
      start_date,
      start_time,
      end_date,
      ends_on,
      end_time,
      all_day,
      completed,
      send_invite,
      color,
      description,
      event_detail,
      event_guests,
      owner,
      nthWeek,
      location,
      phone,
      campaign_trigger_id
    } = this.state.eventForm;

    const recurrence = this.freqPresent
      ? new RRule({
          ...rrule,
          byweekday: rrule.byweekday,
          dtstart: null,
          until: null
        }).toString()
      : null;
    let data = {
      id,
      type: selectedType && selectedType.value,
      recurrence,
      recurrence_option: selectedRecurrence.value && recurrence_option,
      title: title ? title : selectedType.label,
      start_date: start_date && moment(start_date).format('MM/DD/YYYY'),
      end_date: end_date && moment(end_date).format('MM/DD/YYYY'),
      ends_on:
        ends_on &&
        moment(end_date).set('hour', 0).diff(moment(start_date).set('hour', 0), 'days') == 1 &&
        moment(ends_on).set('hour', 0).diff(moment(start_date).set('hour', 0), 'days') == 0
          ? moment(ends_on).add(1, 'days').format('MM/DD/YYYY')
          : ends_on && moment(ends_on).format('MM/DD/YYYY'),
      nthWeek,
      all_day,
      send_invite,
      color,
      description,
      location,
      phone,
      campaign_trigger_id,
      owner,
      event_detail:
        event_guests.find(({ guest }) => 'eventDetail' === guest.type) || event_detail.delete === '1'
          ? event_detail
          : null,
      event_guests: event_guests
        .filter(({ guest }) => ['Contact', 'User'].includes(guest.type))
        .map(data => ({
          delete: data.delete,
          guestable_id: data.guest.id,
          guestable_type: data.guest.type
        }))
    };
    if (!all_day) {
      data = {
        ...data,
        start_time: start_time && moment(start_time).format('HH:mm'),
        end_time: end_time && moment(end_time).format('HH:mm'),
        all_day: !start_time && !end_time
      };
    }
    if (this.props.currentUser.c_1) data.completed = completed;

    return data;
  }

  closeRecurringModal = () => this.setState({ recurringModalVisible: false });

  get recurrenceModal() {
    if (!this.state.recurringModalVisible) return null;
    else {
      return (
        <Modal header={'Edit recurring event'} onClose={this.closeRecurringModal}>
          <div className={styles.typeWrapper}>
            <div className={classNames('field', styles.field)}>
              <input
                disabled={this.props.disabledFields}
                id="recurrence_option_current"
                type="radio"
                name="recurrence_option"
                className="is-checkradio is-medium"
                onChange={() =>
                  this.setState({
                    eventForm: { ...this.state.eventForm, recurrence_option: 'current' }
                  })
                }
                checked={this.state.eventForm.recurrence_option === 'current'}
              />
              <label htmlFor="recurrence_option_current">
                <p>This event</p>
              </label>
            </div>
            <div className={classNames('field', styles.field)}>
              <input
                disabled={this.props.disabledFields}
                id="recurrence_option_following"
                type="radio"
                name="recurrence_option"
                className="is-checkradio is-medium"
                onChange={() =>
                  this.setState({
                    eventForm: { ...this.state.eventForm, recurrence_option: 'following' }
                  })
                }
                checked={this.state.eventForm.recurrence_option === 'following'}
              />
              <label htmlFor="recurrence_option_following">
                <p>This and following events</p>
              </label>
            </div>
            <div className={classNames('field', styles.field)}>
              <input
                disabled={this.props.disabledFields}
                id="recurrence_option_all"
                type="radio"
                name="recurrence_option"
                className="is-checkradio is-medium"
                onChange={() =>
                  this.setState({
                    eventForm: { ...this.state.eventForm, recurrence_option: 'all' }
                  })
                }
                checked={this.state.eventForm.recurrence_option === 'all'}
              />
              <label htmlFor="recurrence_option_all">
                <p>All events</p>
              </label>
            </div>
            <Submit
              label="Ok"
              className={classNames('button is-large is-primary', styles.isPrimary)}
              onSubmit={this.onSubmit}
            />
          </div>
        </Modal>
      );
    }
  }

  cancelEventDetailModal = async () => {
    await this.setStateAsync({
      eventDetailModalVisible: false,
      eventForm: {
        ...this.state.eventForm,
        event_detail: { ...this.initEventDetail, delete: '1' }
      }
    });
  };

  onAddEventDetail = async (e, callback) => {
    const { event_detail } = this.state.eventForm;
    const form = e.target.form;
    const inputs = [...form.elements].filter(i => ['INPUT'].includes(i.nodeName));
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    e.preventDefault();

    if (hasError) {
      return callback();
    }
    await this.setStateAsync({
      eventDetailModalVisible: false,
      eventForm: {
        ...this.state.eventForm,
        event_detail: { ...event_detail, delete: undefined }
      }
    });
    const guest = {
      id: 'eventDetail',
      type: 'eventDetail',
      first_name: event_detail.first_name,
      last_name: event_detail.last_name
    };
    this.selectGuests([
      ...this.state.eventForm.event_guests.filter(({ guest }) => guest.type === 'Contact'),
      {
        guest,
        value: guest.id,
        label: <p>{[guest.first_name, guest.last_name].join(' ')}</p>
      }
    ]);
  };

  render() {
    return (
      <div className={styles.eventModalWrapper}>
        {this.state.eventForm.selectedType && this.modalTabs}
        {this.eventForm}
        {this.state.eventDetailModalVisible && (
          <Modal header={'New contact'} onClose={this.cancelEventDetailModal} classNames={'event-detail-modal'}>
            <EventDetailModal
              onClose={this.cancelEventDetailModal}
              onChangeEventDetail={this.validateOnChangeWithChild}
              event_detail={this.state.eventForm.event_detail}
              hasError={this.hasError}
              onSubmit={this.onAddEventDetail}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  activeCalendarRange: state.event.activeCalendarRange,
  campaign_triggers: state.contacts.campaign_triggers
});

export default connect(mapStateToProps, {
  fetchEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  searchContacts,
  searchUsers,
  fetchContactEvents,
  fetchSmsCampaignTriggers
})(withErrorBoundary(EventModal));
